import { defineStore } from 'pinia'
import { checkQueryStatus, pullColumns, pullResults } from '@/services/quanderApi';


export const useQueryResultsStore = defineStore('queryResults', {
  state: () => {
    return {
      groupId: '',
      troveId: '',
      queryId: '',
      columnsLoaded: false,
      firstRowsLoaded: false,
      loading: false,
      pagingToken: null,
      hasNext: true,
      queryStatus: 'Initializing',
      queryMessage: 'Checking on things',
      messageType: 'INFO',
      rows: [],
      columns: [],
    };
  },
  actions: {
    async waitForQueryCompletion(groupId, troveId, queryId) {
      if (this.queryHasChanged(groupId, troveId, queryId)) {
        this.resetState(groupId, troveId, queryId);
      }
      while (!this.queryHasCompleted) {
        const response = await checkQueryStatus(groupId, troveId, queryId);
        if (this.queryHasChanged(groupId, troveId, queryId)) {
          return;
        }
        this.queryStatus = response.status;
        this.queryMessage = response.message;
        this.messageType = response.messageType;
        if (!this.queryHasCompleted) {
          await new Promise(r => setTimeout(r, 1500));
        }
      }
    },
    async loadColumns(groupId, troveId, queryId) {
      await this.waitForQueryCompletion(groupId, troveId, queryId);
      if (this.queryStatus === "FAILED") {
        return
      }
      const response = await pullColumns(groupId, troveId, queryId);
      if (this.queryHasChanged(groupId, troveId, queryId)) {
        return;
      }
      this.columns = response;
      this.columnsLoaded = true;
    },
    async pageInRows(groupId, troveId, queryId) {
      await this.waitForQueryCompletion(groupId, troveId, queryId);
      if (this.queryStatus === "FAILED") {
        this.hasNext = false;
        return
      }
      const response = await pullResults(groupId, troveId, queryId, this.pagingToken);
      this.pagingToken = response.cursor;
      this.hasNext = response.hasNextPage;
      this.rows.push(...response.rows);
      this.firstRowsLoaded = true;
    },
    async loadAllRows(groupId, troveId, queryId) {
      while(this.hasNext) {
        await this.pageInRows(groupId, troveId, queryId);
      }
    },
    resetState(groupId, troveId, queryId) {
      this.groupId = groupId || '';
      this.troveId = troveId || '';
      this.queryId = queryId || '';
      this.columnsLoaded = false,
      this.firstRowsLoaded = false,
      this.pagingToken = null;
      this.hasNext = true;
      this.rows = [];
      this.columns = [];
      this.queryStatus = 'Initializing';
      this.queryMessage = 'Checking on things';
      this.messageType = 'INFO';
    }
  },
  getters: {
    queryHasChanged: (state) => {
      function checker(groupId, troveId, queryId) {
        return (
          state.groupId !== groupId ||
          state.troveId !== troveId ||
          state.queryId !== queryId
        );
      }
      return checker;
    },
    queryHasCompleted: (state) => {
      return state.queryStatus === 'FINISHED' || state.queryStatus === 'FAILED';
    },
    minimallyLoaded: (state) => {
      return state.columnsLoaded && state.firstRowsLoaded;
    },
    resultsFullyLoaded: (state) => {
      return !state.hasNext;
    }
  }
})
