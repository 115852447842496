import yaml from "js-yaml";

import { fetchGraphQL } from './common';


export async function pullDefaultDataSets(groupId, troveId, cursor) {
  const query = `
    query PullDataSets($group_id: String!, $trove_id: String!, $after: String) {
      trove(group_id: $group_id, trove_id: $trove_id) {
        data_sets(after: $after) {
          edges {
            node {
              data_set_id
              default {
                description
                manifest_url
              }
            }
          }
          page_info {
            end_cursor
            has_next_page
          }
        }
      }
    }`;
    const variables = {'group_id': groupId, 'trove_id': troveId};
    if (cursor !== null) {
      variables.after = cursor;
    }
    const result = await fetchGraphQL(query, variables);
    const edges = result.data.trove.data_sets.edges;
    const dataSets = edges.map(
      edge => {
        return {
          dataSetId: edge.node.data_set_id,
          description: edge.node.default.description,
          manifestUrl: edge.node.default.manifest_url,
        };
      }
    );
    const endCursor = result.data.trove.data_sets.page_info.end_cursor;
    const hasNextPage = result.data.trove.data_sets.page_info.has_next_page;
    return { cursor: endCursor, dataSets, hasNextPage };
}

/**
 *
 * @param {String} groupId The id of the group in which the trove resides.
 * @param {String} troveId The id of the trove itself
 * @returns An object describing the trove.
 */
export async function pullTrove(groupId, troveId) {
  const query = `
    query PullTrove($group_id: String!, $trove_id: String!) {
      trove(group_id: $group_id, trove_id: $trove_id) {
        name
        description
        data_sets {
          edges {
            node {
              data_set_id
              default {
                description
                manifest_url
              }
            }
          }
          page_info {
            end_cursor
            has_next_page
          }
        }
      }
    }`;
    const variables = {'group_id': groupId, 'trove_id': troveId};
    const result = await fetchGraphQL(query, variables);
    if (result.errors && result.errors[0].errorType === "NotFoundError") {
      throw { type: "NotFoundError", message: `Could not find ${troveId}.`}
    }
    if (result.errors) {
      throw { type: "GenericError", message: "An error occured."}
    }
    const { name, description } = result.data.trove;
    const edges = result.data.trove.data_sets.edges;
    const dataSets = edges.map(
      edge => {
        return {
          dataSetId: edge.node.data_set_id,
          description: edge.node.default.description,
          manifestUrl: edge.node.default.manifest_url,
        };
      }
    );
    const endCursor = result.data.trove.data_sets.page_info.end_cursor;
    const hasNextPage = result.data.trove.data_sets.page_info.has_next_page;
    return {
      groupId,
      troveId,
      name,
      description,
      dataSets,
      endCursor,
      hasNextPage,
    };
}

export async function pullDataSet(signedUrl) {
  const response = await fetch(signedUrl, { method: 'GET' });
  return yaml.load(await response.text());
}
