<template lang="pug">
CommonLayout(:crumbItems="crumbs" :loading="!loaded")
  .main
    h4.title.is-4 Query Summary
    h5.title.is-5 Details
    .detailSection
      div
        b Trove Id:
        span.detailValue {{ troveId }}
      div
        b Query Id:
        span.detailValue {{ queryId }}
      div
        b Status:
        span.detailValue {{ status }}
      div
        b Message:
        span.detailValue {{ message }}
      div
        b Submitted at:
        span.detailValue {{ formattedSubmittedAt }}
      div
        b Runtime:
        span.detailValue {{ Math.round(runtime * 100) / 100 }} seconds
    h5.title.is-5 SQL
    .detailSection
      pre
        code {{ queryText }}
</template>
<script>
import { mapActions, mapState } from 'pinia'
import { queryIdToLinks } from '@/router'
import CommonLayout from '@/templates/CommonLayout.vue'
import { useQueryDetailsStore } from '@/store'

export default {
  name: 'QueryDetails',
  components: {
    CommonLayout,
  },
  computed: {
    crumbs: function () {
      return [
        ...queryIdToLinks(this.groupId, this.troveId, this.queryId),
        {text: "details", isActive: true, to: {}}
      ];
    },
    groups: function () {
      return this.$route.params.group;
    },
    groupId: function () {
      return this.$route.params.group.join("/");
    },
    troveId: function () {
      return this.$route.params.trove;
    },
    queryId: function () {
      return this.$route.params.queryId;
    },
    formattedSubmittedAt: function () {
      return (
        new Date(this.submittedAt)
      ).toLocaleString( 'sv', { timeZoneName: 'short' } )
    },
    ...mapState(
      useQueryDetailsStore,
      [
        "loaded",
        "queryText",
        "status",
        "submittedAt",
        "runtime",
        "message",
        "messageType",
      ]
    ),
  },
  methods: {
    ...mapActions(useQueryDetailsStore, ["loadQueryDetails"])
  },
  beforeMount: function () {
    this.loadQueryDetails(this.groupId, this.troveId, this.queryId);
  }
}
</script>
<style lang="scss" scoped>
  .main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
  .detailSection {
    margin-left: 1.5em;
    margin-bottom: 1.5em;
  }
  .detailValue {
    margin-left: 0.5em;
  }
</style>
