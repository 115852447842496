import { defineStore } from 'pinia'


export const useGroupsStore = defineStore('groups', {
  state: () => {
    return {
      group: {}
    };
  },
  actions: {
    async loadGroup(groupId) {
      const base = {
        'ad-publicum': {
          error: null,
          name: 'Ad Publicum',
          id: 'ad-publicum',
          isPersonal: false,
          subgroups: [
            {
              name: 'Topical',
              id: 'ad-publicum/topical',
              description: 'More topical troves of data.'
            }
          ],
          troves: [
            {
              name: 'SSA Names',
              id: 'ssa-names',
              description: 'A trove of name data from the Social Security Administration'
            },
            {
              name: 'MPCA Lakes',
              id: 'mpca-lakes',
              description: 'Data on water quality in Minnesota lakes.'
            },
          ]
        },
        'ad-publicum/topical': {
          error: null,
          name: 'Topical',
          id: 'ad-publicum/topical',
          isPersonal: false,
          subgroups: [],
          troves: [
            {
              name: 'MN Snow Plows',
              id: 'mn-snow-plows',
              description: 'Data on minnesota snow plow deployments.'
            }
          ]
        },
        'kjschiroo': {
          error: null,
          name: 'Kevin Schiroo',
          id: 'kjschiroo',
          isPersonal: true,
          subgroups: [],
          troves: [
            {
              name: 'SSA Names',
              id: 'ssa_names',
              description: 'A trove of name data sourced from the Social Security Administration.',
            },
            {
              name: 'MPCA Water',
              id: 'mpca_water',
              description: 'A trove of data from the Minnesota Pollution Control Agency on the quality of various bodies of water throughout the state.',
            },
            {
              name: 'Real estate listing data',
              id: 'real_estate',
              description: 'A trove of data on real estate listings over time.',
            },
          ],
        }
      };
      this.group = base[groupId];
    }
  }
})
