<template lang="pug">
.NotFound
  div 404 Page not found.
</template>

<script>
export default {
  name: 'NotFound',
}
</script>

<style lang="scss" scoped>
  .NotFound {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
