<template lang="pug">
.Logout
  Loader(value="Redirecting...")
</template>

<script>
import Loader from "@/components/Loader";
import { createLogoutLink, logout, isLoggedIn } from "@/services/authorization";

const REDIRECT_KEY = "logoutRedirect"

async function handleLeaving() {
  window.localStorage.removeItem(REDIRECT_KEY);
  const redirect = decodeURIComponent(this.$route.query.redirect);
  if (redirect !== undefined) {
    window.localStorage.setItem(REDIRECT_KEY, redirect);
  }
  logout();
  window.location.replace(await createLogoutLink());
}

async function handleReturning() {
  const redirect = window.localStorage.getItem(REDIRECT_KEY);
  if (redirect) {
    window.location.replace(redirect);
    return;
  }
  window.location.replace("https://lets-quander.com");
}

export default {
  name: 'Logout',
  components: {
    Loader,
  },
  methods: {
    handleLeaving,
    handleReturning,
  },
  mounted: function() {
    if (!isLoggedIn()) {
      this.handleReturning();
      return;
    }
    this.handleLeaving();
  }
}
</script>

<style lang="scss" scoped>
  .Logout {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
