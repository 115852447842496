import { defineStore } from 'pinia'
import { getQueryDetails } from '@/services/quanderApi';


export const useQueryDetailsStore = defineStore('queryDetails', {
  state: () => {
    return {
      groupId: '',
      troveId: '',
      queryId: '',
      loaded: false,
      queryText: '',
      status: '',
      submittedAt: '',
      runtime: '',
      message: '',
      messageType: '',
      };
  },
  actions: {
    async loadQueryDetails(groupId, troveId, queryId) {
      if (this.queryHasChanged(groupId, troveId, queryId)) {
        this.resetState(groupId, troveId, queryId);
      }
      const response = await getQueryDetails(groupId, troveId, queryId);
      this.queryText = response.queryText;
      this.status = response.status;
      this.submittedAt = response.submittedAt;
      this.runtime = response.runtime;
      this.message = response.message;
      this.messageType = response.messageType;
      this.loaded = true;
    },
    resetState(groupId, troveId, queryId) {
      this.groupId = groupId || '';
      this.troveId = troveId || '';
      this.queryId = queryId || '';
      this.loaded = false;
      this.queryText = '';
      this.status = '';
      this.submittedAt = '';
      this.runtime = '';
      this.message = '';
      this.messageType = '';
    },
  },
  getters: {
    queryHasChanged: (state) => {
      function checker(groupId, troveId, queryId) {
        return (
          state.groupId !== groupId ||
          state.troveId !== troveId ||
          state.queryId !== queryId
        );
      }
      return checker;
    },
  }
})
