<template lang="pug">
.MinimalLayout
  NavBar.GaTop(:isLoggedIn="isLoggedIn")
  .GaMain
    slot(v-if="!loading")
    loader.Loader(
      v-else
      :value="loadingMessage"
      :subMessage="loadingSubMessage"
      :buttonText="loadingButtonText"
      v-on:buttonClick="$emit('loaderButtonClick')"
    )
</template>

<script>
import { isLoggedIn } from "@/services/authorization";
import NavBar from '@/components/NavBar.vue'
import Loader from '@/components/Loader.vue'

export default {
  name: 'MinimalLayout',
  components: {
    NavBar,
    Loader,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    loadingMessage: {
      type: String,
      default: "Loading",
    },
    loadingSubMessage: {
      type: String,
      default: null,
    },
    loadingButtonText: {
      type: String,
      default: null,
    },
    crumbItems: Array,
    sideItems: Array,
  },
  computed: {
    isLoggedIn,
  },

}
</script>

<style lang="scss" scoped>
  $breakPoint: 1350px;

  .MinimalLayout {
    display: grid;
    min-height: 98vh;
    grid-template-rows: auto 1fr;
    grid-template-columns: 1fr;
    grid-template-areas:
      "top"
      "main";
  }
  .GaTop {
    grid-area: top;
  }
  .GaMain {
    grid-area: main;
    flex-grow: 1;
    width: 100%;

    display: flex;
  }

  .Loader {
    height: 95vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
