<template lang="pug">
nav.breadcrumb.BreadCrumbs(aria-label="breadcrumbs")
  ul
    li(
        v-for="item in items" :key="item.to"
        :class="{'is-active': item.isActive}"
    )
      router-link(:to="item.to") {{item.text}}
</template>
<script>

export default {
  props: {
    items: {
        type: Array,
        required: true,
    },
  }
}
</script>
<style lang="scss" scoped>
.BreadCrumbs {
  display: flex;
  align-items: center;
}
</style>