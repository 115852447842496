import { createRouter, createWebHistory } from "vue-router";
import Group from "@/views/Group";
import TroveEditor from "@/views/TroveEditor";
import Templates from "@/views/Templates";
import Template from "@/views/Template";
import TemplateEditor from "@/views/TemplateEditor";
import QueryDetails from "@/views/QueryDetails";
import QueryResults from "@/views/QueryResults";
import Login from "@/views/Login";
import Logout from "@/views/Logout";
import NotFound from "@/views/NotFound";

const routes = [
  {
    path: "/u/:group+",
    name: "Group",
    component: Group,
  },
  {
    path: "/u/:group+/-/members",
    name: "GroupMembers",
  },
  {
    path: "/u/:group+/-/billing",
    name: "GroupBilling",
  },
  {
    path: "/u/:group+/-/t/:trove",
    name: "Trove",
    component: TroveEditor,
  },
  {
    path: "/u/:group+/-/t/:trove/docs",
    name: "TroveDocs",
  },
  {
    path: "/u/:group+/-/t/:trove/editor",
    name: "TroveEditor",
    component: TroveEditor,
  },
  {
    path: "/u/:group+/-/t/:trove/results/:queryId",
    name: "QueryResults",
    component: QueryResults,
  },
  {
    path: "/u/:group+/-/t/:trove/results/:queryId/details",
    name: "QueryDetails",
    component: QueryDetails,
  },
  {
    path: "/u/:group+/-/t/:trove/templates",
    name: "TroveTemplates",
    component: Templates,
  },
  {
    path: "/u/:group+/-/t/:trove/templates/:templateId",
    name: "TroveTemplate",
    component: Template,
  },
  {
    path: "/u/:group+/-/t/:trove/saved-queries/:username",
    name: "TroveSavedQueries",
    component: Templates,
  },
  {
    path: "/u/:group+/-/t/:trove/saved-queries/:username/:access/queries/:templateId",
    name: "TroveSavedQuery",
    component: Template,
  },
  {
    path: "/u/:group+/-/t/:trove/saved-queries/:username/:access/queries/:templateId/edit",
    name: "TroveEditQuery",
    component: TemplateEditor,
  },
  {
    path: "/u/:group+/-/t/:trove/save-query",
    name: "TroveSaveQuery",
    component: TemplateEditor,
  },
  {
    path: "/u/:group+/-/t/:trove/members",
    name: "TroveMembers",
  },
  {
    path: "/logout",
    name: "Logout",
    component: Logout,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/",
    redirect: "/u/kjschiroo",
  },
  {
    path: "/not-found",
    name: "NotFound",
    component: NotFound,
  }
]

export const router = createRouter({
  history: createWebHistory(),
  routes
});
