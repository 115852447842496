/**
 * Transform a group id into a list of links for every subgroup.
 *
 * @param {String} groupId The group id to render links for.
 * @param {Boolean} lastIsActive Whether the last link should be marked as active.
 *
 * @returns A list of vue router link objects.
 */
export function groupIdToLinks (groupId, lastIsActive) {
  const groupParts = groupId.split('/')
  const links = groupParts.reduce((reduced, val) => {
    let lastGroup = [];
    if (reduced.length !== 0) {
      lastGroup = reduced.at(-1).to.params.group
    }
    reduced.push({
      text: val,
      isActive: false,
      to: {
        name: 'Group',
        params: { group: [...lastGroup, val]}
      }
    });
    return reduced;
  }, []);
  links[links.length - 1].isActive = lastIsActive;
  return links;
}

/**
 * Transform a group id and trove id into a set of vue router links to navigate
 * to each group in the hierarchy as well as the trove.
 *
 * @param {String} groupId The group id to render links for.
 * @param {String} troveId The trove id to render links for.
 * @param {Boolean} lastIsActive Whether to mark the last link as active.
 *
 * @returns A list of vue router link objects.
 */
export function troveIdToLinks (groupId, troveId, lastIsActive) {
  const links = groupIdToLinks(groupId, false);
  links.push({
    text: troveId,
    isActive: lastIsActive,
    to: {
      name: 'Trove',
      params: { group: groupId.split('/'), trove: troveId},
    }
  });
  return links;
}

/**
 * Transform a group id, trove id, and query id into a set of vue router links
 * to navigate to each entity in the chain.
 *
 * @param {String} groupId The group id to render links for.
 * @param {String} troveId The trove id to render links for.
 * @param {String} queryId The query id to render links for.
 * @param {Boolean} lastIsActive Whether to mark the last link as active.
 */
 export function queryIdToLinks (groupId, troveId, queryId, lastIsActive) {
  const links = troveIdToLinks(groupId, troveId, false);
  links.push({
    text: `Result: ${queryId}`,
    isActive: lastIsActive,
    to: {
      name: 'QueryResults',
      params: { group: groupId.split('/'), trove: troveId, queryId},
    }
  });
  return links;
}

/**
 * Create a set of links for a set of templates within a trove within a group.
 *
 * @param {String} groupId The group id to render links for.
 * @param {String} troveId The trove id to render links for.
 * @param {Boolean} lastIsActive Whether to mark the last link as active.
 * @returns A set of links down to the trove's templates.
 */
export function templatesLinks (groupId, troveId, lastIsActive) {
  const links = troveIdToLinks(groupId, troveId, false);
  links.push({
    text: "Templates",
    isActive: lastIsActive,
    to: {
      name: "TroveTemplates",
      params: {
          group: groupId.split('/'),
          trove: troveId,
      }
    }
  });
  return links;
}

/**
 * Create a set of links for a template within a trove within a group.
 *
 * @param {String} groupId The group id to render links for.
 * @param {String} troveId The trove id to render links for.
 * @param {String} templateId The template id to render links for.
 * @param {Boolean} lastIsActive Whether to mark the last link as active.
 * @returns A set of links down to the template.
 */
 export function templateLinks (groupId, troveId, templateId, lastIsActive) {
  const links = templatesLinks(groupId, troveId, false);
  links.push({
    text: templateId,
    isActive: lastIsActive,
    to: {
      name: "TroveTemplate",
      params: {
          group: groupId.split('/'),
          trove: troveId,
          templateId,
      }
    }
  });
  return links;
}

/**
 * Create a set of links for a given saved query set.
 *
 * @param {String} groupId The group id to render links for.
 * @param {String} troveId The trove id to render links for.
 * @param {String} username The username to which the queries belong.
 * @param {Boolean} lastIsActive Whether to mark the last link as active.
 * @returns A set of linkds down to the saved queries.
 */
export function savedQueriesLinks (groupId, troveId, username, lastIsActive) {
  const links = troveIdToLinks(groupId, troveId, false);
  links.push({
    text: `${username}'s saved queries'`,
    isActive: lastIsActive,
    to: {
      name: "TroveSavedQueries",
      params: {
        group: groupId.split('/'),
        trove: troveId,
        username,
      }
    }
  });
  return links;
}

/**
 * Create a set of links for a given saved query.
 *
 * @param {String} groupId The group id to render links for.
 * @param {String} troveId The trove id to render links for.
 * @param {String} username The username to which the queries belong.
 * @param {String} access The access type of the query, public or private.
 * @param {String} savedQueryId The id of the saved query.
 * @param {Boolean} lastIsActive Whether to mark the last link as active.
 * @returns A set of links down to the saved query.
 */
 export function savedQueryLinks (groupId, troveId, username, access, savedQueryId, lastIsActive) {
  const links = savedQueriesLinks(groupId, troveId, username, false);
  links.push({
    text: savedQueryId,
    isActive: lastIsActive,
    to: {
      name: "TroveSavedQuery",
      params: {
        group: groupId.split('/'),
        trove: troveId,
        username,
        access,
        templateId: savedQueryId,
      }
    }
  });
  return links;
}