<template lang="pug">
.NavBar
  img.NavBar__logo(src="@/assets/logo.svg")
  div(v-if="!hideLoginButton && !isLoggedIn")
    router-link.button(:to="loginLink") Login
  .dropdown.is-hoverable.is-right(v-if="!hideLoginButton && isLoggedIn")
    .dropdown-trigger.NavBar_right
      img.icon(src="@/assets/personal.svg")
      img.icon(src="@/assets/down-arrow.svg")
    .dropdown-menu
      .dropdown-content
        .dropdown-item
          router-link(:to="logoutLink") Logout
</template>
<script>
export default {
  props: {
    isLoggedIn: Boolean,
    hideLoginButton: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    logoutLink: function () {
      return { name: 'Logout', query: { redirect: encodeURIComponent(window.location.href) } };
    },
    loginLink: function () {
      return { name: 'Login', query: { redirect: encodeURIComponent(window.location.href) } };
    },
  }
}
</script>
<style lang="scss" scoped>
.NavBar {
  height: 3em;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0.25em 0.75em 0.2em 0.75em;

  &_right {
    display: flex;
    align-items: center;
  }
}
</style>