<template lang="pug">
CommonLayout(:crumbItems="crumbs" :sideItems="sideItems")
  .main
    .HeaderContainer
      h1.title {{ title }}
      div
      router-link.button.is-light(
        v-if="isUsersPage"
        :to="{name: 'TroveSaveQuery', params: { group: groupParts, trove: troveId }}"
      ) Create Query
    Tabs(v-if="viewMode === 'SAVED_QUERIES' && isUsersPage" :items="tabItems")
    SimpleListItem(
      v-for="template in templates"
      :title="template.title"
      :content="template.description"
      :to="templateLink(template.templateId)"
    )
    SimpleListItem(v-if="loading", :useFiller="true")
    SimpleListItem(v-if="loading", :useFiller="true")
    SimpleListItem(v-if="loading", :useFiller="true")
    .NoTemplatesContainer(v-if="templates.length === 0 && !loading") {{ noTemplatesMessage }}
      router-link.button.is-primary.is-large(
        v-if="isUsersPage"
        :to="{name: 'TroveSaveQuery', params: { group: groupParts, trove: troveId }}"
      ) Create your first query!
</template>
<script>
import { mapActions, mapState } from 'pinia'
import { getUsername } from "@/services/authorization";
import { templatesLinks, savedQueriesLinks } from '@/router'
import CommonLayout from '@/templates/CommonLayout.vue'
import Tabs from '@/components/Tabs.vue'
import SimpleListItem from '@/components/SimpleListItem.vue'
import { useTemplatesStore } from '@/store'

export default {
  name: 'Templates',
  components: {
    CommonLayout,
    SimpleListItem,
    Tabs,
  },
  computed: {
    crumbs: function () {
      return (
        this.viewMode === 'TEMPLATES' ?
        templatesLinks(this.groupId, this.troveId, true)
        : savedQueriesLinks(this.groupId, this.troveId, this.pageUsername, true)
      )
    },
    isUsersPage: function () {
      return this.viewMode === "SAVED_QUERIES" && getUsername() === this.pageUsername;
    },
    noTemplatesMessage: function () {
      if (this.viewMode === 'TEMPLATES') {
        return "Trove has no templates yet.";
      }
      if (!this.isUsersPage) {
        return "User has no public queries.";
      }
      return `You have no ${this.panel} saved queries.`;
    },
    troveId: function () {
      return this.$route.params.trove;
    },
    pageUsername: function () {
      return this.$route.params?.username;
    },
    groupId: function () {
      if (!this.$route.params.group) {
        return null;
      }
      return this.$route.params.group.join("/");
    },
    groupParts: function () {
      return this.$route.params.group;
    },
    panel: function () {
      return this.$route.query.panel === 'private' ? 'private' : 'public';
    },
    title: function () {
      return (
        this.viewMode === 'TEMPLATES' ?
        'Trove Templates' : `${this.pageUsername}'s Saved Queries`
      );
    },
    tabItems: function () {
      const onPrivate = this.panel === 'private' && this.isUsersPage;
      return [
        {to: { query: { panel: 'public' } }, text: "Public", isActive: !onPrivate},
        {to: { query: { panel: 'private' } },text: "Private", isActive: onPrivate}

      ];
    },
    sideItems: function () {
      return [];
    },
    viewMode: function () {
      return this.$route.name == 'TroveTemplates' ? 'TEMPLATES' : 'SAVED_QUERIES';
    },
    templateGroupId: function () {
      if (this.viewMode === 'TEMPLATES') {
        return "public";
      }
      return `${this.pageUsername}-${this.panel}`;
    },
    ...mapState(useTemplatesStore, [
      'hasNextPage',
      'templates',
      'loading',
      'marginalLoading',
    ])
  },
  methods: {
    templateLink: function (templateId) {
      if (this.viewMode === 'TEMPLATES') {
        return {
          name: 'TroveTemplate',
          params: {
            group: this.groupParts ,
            trove: this.troveId,
            templateId,
          }
        };
      }
      return {
        name: 'TroveSavedQuery',
        params: {
          group: this.groupParts ,
          trove: this.troveId,
          username: this.pageUsername,
          access: this.panel,
          templateId,
        }
      };
    },
    ...mapActions(useTemplatesStore, ['loadTemplateGroup'])
  },
  beforeMount: function () {
    this.loadTemplateGroup(this.groupId, this.troveId, this.templateGroupId);
  },
  watch: {
    'templateGroupId': function () {
      this.loadTemplateGroup(this.groupId, this.troveId, this.templateGroupId);
    }
  }}
</script>
<style lang="scss" scoped>
  .main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
  .HeaderContainer {
    display: flex;
    justify-content: space-between;
  }
  .NoTemplatesContainer {
    display: flex;
    flex-direction: column;
    color: #808080;
    height: 15em;
    justify-content: center;
    align-items: center;
    font-size: 1.5em;
  }
</style>
