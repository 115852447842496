<template lang="pug">
minimal-layout(
  :loading="!minimallyLoaded"
  :loadingMessage="queryStatus"
  :loadingSubMessage="queryMessage"
  :loadingButtonText="queryStatus == 'FAILED' ? 'Okay' : null"
  @loaderButtonClick="navigateBack"
)
  .QueryResults
    common-secondary-navbar.GaSecondary(:crumbItems="crumbs")
      .buttons
        router-link.button.is-light.ActionButton(
          :to="{name: 'QueryDetails',params: {group: groups, trove: troveId, queryId: queryId}}"
        )
          span Details
          span.icon
            img(src="@/assets/info.svg")
        button.button.ActionButton(
          v-if="resultsFullyLoaded"
          @click="copyToClipboard"
        )
          span {{ recentlyCopied ? "Copied" : "Copy" }}
          span.icon
            img(v-if="recentlyCopied" src="@/assets/check.svg")
            img(v-else src="@/assets/copy.svg")
        button.button.ActionButton(v-if="!resultsFullyLoaded" disabled)
          span Copy
          span.icon
            img(src="@/assets/pulse.svg")
    .GaMain.table-container.is-fullwidth.ResultTable
      table.table.is-striped.is-fullwidth
        thead.ResultTableHeader
          tr
            th(v-for="n in columnNames") {{ n }}
        tbody
          tr(v-for="row in activeRows")
            td(v-for="v in row") {{ isUrl(v) ? null : v }}
              a(v-if="isUrl(v)" target="_blank" :href="v") {{ v }}
    .GaBottom
      nav.pagination.is-centered.is-small(role="navigation" aria-label="pagination")
        router-link.pagination-previous(
          v-if="hasPrevious"
          :to="generateNavLink(pageNumber - 1)"
        ) Previous
        a.pagination-previous(v-else disabled) Previous
        router-link.pagination-next(
          v-if="hasNext"
          :to="generateNavLink(pageNumber + 1)"
        ) Next
        a.pagination-next(v-else disabled) Next
</template>
<script>
import { mapActions, mapState } from 'pinia'
import MinimalLayout from '@/templates/MinimalLayout.vue';
import { queryIdToLinks } from '@/router';
import CommonSecondaryNavbar from '@/components/CommonSecondaryNavbar.vue';
import Loader from '@/components/Loader.vue';
import { useQueryResultsStore } from '@/store';

const PAGE_SIZE = 100;

function assembleForClipboard(columnTypes, queryResults) {
  const headerNames = columnTypes.map(col => col.name);
  const allRows = [headerNames, ...queryResults];
  return allRows.map(row => row.join('\t')).join('\n');
}

function copyToClipboard() {
  navigator.clipboard.writeText(
    assembleForClipboard(this.columns, this.rows)
  );
  this.recentlyCopied = true;
  setTimeout(() => this.recentlyCopied = false, 2000);
}

function generateNavLink(page) {
  const base = {...this.$route};
  base.query = {...base.query, page};
  return base;
}

function navigateBack() {
  if (this.$router.options.history.state.back) {
    this.$router.go(-1);
    return;
  }
  this.$router.push(
    {name: "TroveEditor", params: {group: this.groups, trove: this.troveId}}
  );
}

function isUrl(value) {
  const asString = `${value}`;
  return asString.startsWith('http://') || asString.startsWith('https://');
}

export default {
  name: 'QueryResults',
  components: {
    CommonSecondaryNavbar,
    Loader,
    MinimalLayout,
  },
  computed: {
    crumbs: function () {
      return queryIdToLinks(this.groupId, this.troveId, this.queryId, true);
    },
    groupId: function () {
      return this.$route.params.group.join("/");
    },
    groups: function () {
      return this.$route.params.group;
    },
    troveId: function () {
      return this.$route.params.trove;
    },
    queryId: function () {
      return this.$route.params.queryId;
    },
    pageNumber: function () {
      return parseInt(this.$route.query?.page) || 0;
    },
    activeRows: function () {
      const position = PAGE_SIZE * this.pageNumber
      return this.rows.slice(position, position + PAGE_SIZE);
    },
    columnNames: function () {
      return this.columns.map(v => v.name)
    },
    hasPrevious: function () {
      return this.pageNumber > 0;
    },
    hasNext: function () {
      return (this.pageNumber + 1) * PAGE_SIZE < this.rows.length;
    },
    ...mapState(useQueryResultsStore, ["minimallyLoaded", "queryMessage", "queryStatus", "rows", "columns", "resultsFullyLoaded"]),
  },
  data: () => {
    return {
      recentlyCopied: false,
    }
  },
  methods: {
    copyToClipboard,
    generateNavLink,
    isUrl,
    navigateBack,
    ...mapActions(useQueryResultsStore, ['waitForQueryCompletion', 'loadColumns', 'loadAllRows'])
  },
  beforeMount: function () {
    this.waitForQueryCompletion(this.groupId, this.troveId, this.queryId).then(() => {
      this.loadColumns(this.groupId, this.troveId, this.queryId);
      this.loadAllRows(this.groupId, this.troveId, this.queryId);
    });
  },
  watch: {
    "$route.query.page": function () {
      window.scrollTo(0, 0);
    }
  },
}
</script>
<style lang="scss" scoped>
  .QueryResults {
    flex-grow: 1;
    max-width: 100vw;
    display: grid;
    grid-template-rows: auto auto 1fr;
    grid-template-columns: 1fr;
    grid-template-areas:
      "secondary"
      "main"
      "bottom";
  }
  .GaSecondary {
    grid-area: secondary;
    padding-left: 1em;
  }
  .GaMain {
    grid-area: main;
    padding-left: 1em;
    padding-right: 1em;
  }
  .GaBottom {
    grid-area: bottom;
    padding-left: 1em;
    padding-right: 1em;
    padding-bottom: 1em;
  }
  .ResultTable {
    overflow-y: scroll;
    max-height: 80vh;
  }
  .ResultTableHeader {
    position: sticky;
    top: 0px;
    background: white;
  }
  .ActionButton {
    display: flex;
    align-items: center;
    justify-content: center;
  }

</style>
