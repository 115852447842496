<template lang="pug">
.CommonSecondaryNavbar
  BreadCrumbs(:items="crumbItems")
  .RightComponent
    slot

</template>
<script>
import BreadCrumbs from "@/components/BreadCrumbs";

export default {
  name: 'CommonSecondaryNavbar',
  components: {
    BreadCrumbs,
  },
  props: {
    crumbItems: {
        type: Array,
        required: true,
    },
  }
}
</script>
<style lang="scss" scoped>
.CommonSecondaryNavbar {
  display: flex;
  justify-content: space-between;
  align-content: center;
  margin-top: 12px;
}

.RightComponent {
  margin-bottom: 24px;
  margin-right: 0.75em;
}
</style>