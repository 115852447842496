<template lang="pug">
.CommonLayout
  NavBar.GaTop(:isLoggedIn="isLoggedIn")
  BreadCrumbs.GaSecondary(:items="crumbItems")
  SideMenu.GaLeft(v-if="sideItems.length > 0" :items="sideItems")
  .GaMain
    slot(v-if="!loading")
    loader.Loader(
      v-else
      :value="loadingMessage"
      :subMessage="loadingSubMessage"
      :buttonText="loadingButtonText"
      v-on:buttonClick="$emit('loaderButtonClick')"
    )
</template>

<script>
import { isLoggedIn } from "@/services/authorization";
import NavBar from '@/components/NavBar.vue'
import BreadCrumbs from '@/components/BreadCrumbs.vue'
import Loader from '@/components/Loader.vue'
import SideMenu from '@/components/SideMenu.vue'

export default {
  name: 'CommonLayout',
  components: {
    Loader,
    NavBar,
    BreadCrumbs,
    SideMenu,
  },
  computed: {
    isLoggedIn,
  },
  props: {
    crumbItems: Array,
    sideItems: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    loadingMessage: {
      type: String,
      default: "Loading",
    },
    loadingSubMessage: {
      type: String,
      default: null,
    },
    loadingButtonText: {
      type: String,
      default: null,
    },
  }
}
</script>

<style lang="scss" scoped>
$breakPoint: 1350px;

.CommonLayout {
  display: grid;
  min-height: 100vh;
  grid-template-rows: auto auto 1fr;
  @media screen and (min-width: $breakPoint) {
    grid-template-columns: auto 1fr 1050px 1fr auto;
    grid-template-areas:
      "top top top top top"
      "left lspace secondary rspace right"
      "left lspace main rspace right";
  }
  @media screen and (max-width: $breakPoint) {
    grid-template-columns: auto 1fr auto;
    grid-template-areas:
      "top top top"
      "left secondary right"
      "left main right";
  }
}
.GaTop {
  grid-area: top;
}
.GaSecondary {
  grid-area: secondary;
  margin-left: 0.5em;
  margin-right: 0.5em;

}
.GaLeft {
  grid-area: left;
  width: 225px;
  margin-left: 0.5em;
  margin-right: 0.5em;
}
.GaRight {
  grid-area: right;
  margin-left: 0.5em;
  margin-right: 0.5em;
}
.GaMain {
  grid-area: main;
  flex-grow: 1;
  width: 100%;
  margin-left: 0.5em;
  margin-right: 0.5em;

  display: flex;
}
.Loader {
  height: 95vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
