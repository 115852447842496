<template lang="pug">
.Loader
  .Loader_mainMessage {{ value }}
  div(v-if="subMessage") {{ subMessage }}
  img.Loader_img(src="@/assets/pentadecathlon.svg")
  button.button(
    v-if="buttonText"
    v-on:click="$emit('buttonClick'); $event.preventDefault()"
  ) {{ buttonText }}
</template>
<script>
export default {
  name: 'Loader',
  props: {
    value: String,
    subMessage: String,
    buttonText: String,
  }
}
</script>
<style lang="scss" scoped>
.Loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #828282;

  &_img {
    width: 250px;
  }

  &_mainMessage {
    color: #808080;
    font-variant-caps: small-caps;
    font-size: 1.5em;
  }

  &_subMessage {
    color: #808080;
  }

}
</style>
