import { fetchGraphQL } from './common';


export async function submitQuery(groupId, troveId, sqlQuery) {
  const query = `
    mutation SubmitQuery($group_id: String!, $trove_id: String!, $query: String!) {
      submit_query(group_id: $group_id, trove_id: $trove_id, query: $query) {
        query_id
      }
    }
  `;
  const result = await fetchGraphQL(
    query, { 'group_id': groupId, 'trove_id': troveId, 'query': sqlQuery }
  );
  return result.data.submit_query.query_id;
}

export async function checkQueryStatus(groupId, troveId, queryId) {
  const query = `
    query CheckQueryStatus($group_id: String!, $trove_id: String!, $query_id: String!) {
      query_status(group_id: $group_id, trove_id: $trove_id, query_id: $query_id) {
        status
        message
        message_type
      }
    }
  `;
  const result = await fetchGraphQL(
    query, { 'group_id': groupId, 'trove_id': troveId, 'query_id': queryId }
  );
  const { query_status } = result.data;
  return {
    status: query_status.status,
    message: query_status.message,
    messageType: query_status.message_type,
  }
}

export async function pullColumns(groupId, troveId, queryId) {
  const query = `
    query PullColumns($group_id: String!, $trove_id: String!, $query_id: String!) {
      query_results(group_id: $group_id, trove_id: $trove_id, query_id: $query_id) {
        columns {
          name
          type
        }
      }
    }
  `;
  const result = await fetchGraphQL(
    query, { 'group_id': groupId, 'trove_id': troveId, 'query_id': queryId }
  );
  return result.data.query_results.columns;
}

export async function pullResults(groupId, troveId, queryId, cursor) {
  const query = `
    query PullResults($group_id: String!, $trove_id: String!, $query_id: String!, $after: String) {
      query_results(group_id: $group_id, trove_id: $trove_id, query_id: $query_id) {
        rows(after: $after) {
          edges
          page_info {
            end_cursor
            has_next_page
          }
        }
      }
    }
  `;
  const variables = { 'group_id': groupId, 'trove_id': troveId, 'query_id': queryId };
  if (cursor !== null) {
    variables.after = cursor;
  }
  const result = await fetchGraphQL(query, variables);
  const { query_results } = result.data;
  return {
    rows: query_results.rows.edges,
    cursor: query_results.rows.page_info.end_cursor,
    hasNextPage: query_results.rows.page_info.has_next_page,
  };
}

export async function getQueryDetails(groupId, troveId, queryId) {
  const query = `
    query getQueryDetails($group_id: String!, $trove_id: String!, $query_id: String!) {
      query_status(group_id: $group_id, trove_id: $trove_id, query_id: $query_id) {
        query_text
        status
        submitted_at
        runtime
        message
        message_type
      }
    }
  `;
  const result = await fetchGraphQL(
    query, { 'group_id': groupId, 'trove_id': troveId, 'query_id': queryId });
  const { query_status } = result.data;
  return {
    queryText: query_status.query_text,
    status: query_status.status,
    submittedAt: query_status.submitted_at,
    runtime: query_status.runtime,
    message: query_status.message,
    messageType: query_status.message_type,
  };
}
