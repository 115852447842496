<template lang="pug">
router-link.SimpleListItem.Hoverable(v-if="!useFiller" :to="to")
  .SimpleListItem__content
    .has-text-weight-bold {{ title }}
    div {{ content }}
.SimpleListItem(v-else)
  .SimpleListItem__content
    .TitleFiller
    .ContentFiller
</template>
<script>

export default {
  props: {
    title: String,
    content: String,
    to: Object,
    useFiller: {
      type: Boolean,
      default: false,
    }
  }
}
</script>
<style lang="scss" scoped>
.SimpleListItem {
  color: inherit;

  &__content {
    padding-left: 0.75em;
    padding-right: 0.75em;
    padding-top: 0.25em;
    padding-bottom: 0.25em;
    border-top: 1px solid hsl(0, 0%, 92%);
  }
}
.Hoverable:hover {
  background-color: hsl(0, 0%, 96%);
}
.TitleFiller {
  background: whitesmoke;
  width: 20%;
  height: 1.25em;
  margin: 5px;
}
.ContentFiller {
  background: whitesmoke;
  width: 80%;
  height: 1.25em;
  margin: 5px;
}
</style>