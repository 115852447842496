import { fetchGraphQL } from './common';


export async function getTemplates(namespaceId, templateGroupId, pagingToken) {
  const query = `
    query GetTemplates(
      $namespaceId: String!
      $templateGroupId: String!
      $pagingToken: String
    ) {
      template_group(
        namespace_id: $namespaceId
        template_group_id: $templateGroupId
      ) {
        templates(after: $pagingToken) {
          edges {
            node {
              title
              template_id
              qrn
              description
              created_at
              updated_at
            }
          }
          page_info {
            end_cursor
            has_next_page
          }
        }
      }
    }
  `;
  const result = await fetchGraphQL(
    query, { namespaceId, templateGroupId, pagingToken }
  );
  const templates = (result.data.template_group || {}).templates || {};
  const edges = templates.edges || [];
  const page_info = templates.page_info || {};
  const endCursor = page_info.end_cursor || null;
  const hasNextPage = page_info.has_next_page || false;
  return {
    templates: edges.map( edge => {
      return {
        qrn: edge.node.qrn,
        templateId: edge.node.template_id,
        title: edge.node.title,
        description: edge.node.description,
        createdAt: edge.node.created_at,
        updatedAt: edge.node.updated_at,
      };
    }),
    cursor: endCursor,
    hasNextPage,
  };
}

export async function getTemplate(namespaceId, templateGroupId, templateId) {
  const query = `
    query GetTemplate(
      $namespaceId: String!
      $templateGroupId: String!
      $templateId: String!
    ) {
      query_template(
        namespace_id: $namespaceId
        template_group_id: $templateGroupId
        template_id: $templateId
      ) {
        title
        description
        current {
          body
          updated_at
          parameters {
            name
            token
            description
          }
        }
      }
    }
  `;
  const result = await fetchGraphQL(query, { namespaceId, templateGroupId, templateId });
  return {
        title: result.data.query_template.title,
        description: result.data.query_template.description,
        body: result.data.query_template.current.body,
        updatedAt: result.data.query_template.current.updated_at,
        parameters: result.data.query_template.current.parameters,
  };
}

export async function createTemplate(
  namespaceId,
  templateGroupId,
  title,
  description,
  body,
  parameters
) {
  const query = `
    mutation CreateQueryTemplate(
      $namespaceId: String!
      $templateGroupId: String!
      $body: String!
      $description: String!
      $title: String!
      $parameters: [QueryTemplateParameterInput]
    ) {
      create_query_template(
        namespace_id: $namespaceId
        template_group_id: $templateGroupId
        title: $title
        description: $description
        body: $body
        parameters: $parameters
      ) {
        template_id
      }
    }
  `;
  const result = await fetchGraphQL(
    query,
    {
      namespaceId,
      templateGroupId,
      body,
      description,
      title,
      parameters,
    }
  );
  return result.data.create_query_template.template_id;
}

export async function updateTemplate(
  namespaceId,
  templateGroupId,
  templateId,
  title,
  description,
  body,
  parameters
) {
  const query = `
    mutation UpdateQueryTemplate(
      $namespaceId: String!
      $templateGroupId: String!
      $templateId: String!
      $body: String!
      $description: String!
      $title: String!
      $parameters: [QueryTemplateParameterInput]
    ) {
      update_query_template(
        namespace_id: $namespaceId
        template_group_id: $templateGroupId
        template_id: $templateId
        title: $title
        description: $description
        body: $body
        parameters: $parameters
      ) {
        template_id
      }
    }
  `;
  const result = await fetchGraphQL(
    query,
    {
      namespaceId,
      templateGroupId,
      templateId,
      body,
      description,
      title,
      parameters,
    }
  );
  return result.data.update_query_template.template_id;
}