import { defineStore } from 'pinia';

import { pullDataSet, pullTrove, pullDefaultDataSets } from '@/services/quanderApi';


function loadAllActiveQueries() {
  const serialized = localStorage.getItem('activeQueries') || "{}";
  let existing = {};
  try {
    existing = JSON.parse(serialized);
  } catch {
    // Do nothing
  }
  return existing;
}


function replaceStoredActiveQuery(groupId, troveId, activeQuery) {
  const stored = loadAllActiveQueries();
  stored[`${groupId}/-/${troveId}`] = activeQuery;
  localStorage.setItem('activeQueries', JSON.stringify(stored));
}


function getStoredActiveQuery(groupId, troveId) {
  const stored = loadAllActiveQueries();
  const query = stored[`${groupId}/-/${troveId}`]
  return query === undefined ? null : query;
}


export function pushStoredActiveQuery(groupId, troveId, query) {
  const previousQuery = getStoredActiveQuery(groupId, troveId);
  if (previousQuery) {
    pushStoredQueryHistory(groupId, troveId, previousQuery);
  }
  replaceStoredActiveQuery(groupId, troveId, query);
}

function getQueryHistory(groupId, troveId) {
  const serialized = localStorage.getItem('queryHistory') || "[]";
  let history = [];
  try {
    history = JSON.parse(serialized);
  } catch {
    // Do nothing
  }
  const troveKey = `${groupId}/-/${troveId}`;
  return history.filter(v => v.troveKey === troveKey).map(v => v.query);
}

function pushStoredQueryHistory(groupId, troveId, query) {
  const serialized = localStorage.getItem('queryHistory') || "[]";
  let existing = [];
  try {
    existing = JSON.parse(serialized);
  } catch {
    // Do nothing
  }
  const troveKey = `${groupId}/-/${troveId}`;
  const newHistory = [{ troveKey, query }, ...existing];
  let cut = 250;
  const megabyte = 1000000;
  while (JSON.stringify(newHistory.slice(0, cut)) > megabyte) {
    cut--;
  }
  localStorage.setItem('queryHistory', JSON.stringify(newHistory.slice(0, cut)));
  return newHistory.filter(v => v.troveKey === troveKey).map(v => v.query);
}


export const useTrovesStore = defineStore('troves', {
  state: () => {
    return {
      trove: {
        groupId: "",
        troveId: "",
        name: "",
        description: "",
        loading: true,
        datasets: [],
      },
      datasets: {},
      rawDataSets: [],
      datasetsCursor: null,
      hasAdditionalDataSets: false,
      activeQuery: "",
      queryHistory: [],
    };
  },
  getters: {
    troveHasChanged: (state) => {
      function checker(groupId, troveId) {
        return (
          state.trove.groupId !== groupId ||
          state.trove.troveId !== troveId
        );
      }
      return checker;
    },
  },
  actions: {
    async loadTrove(groupId, troveId) {
      // If we aren't in a different trove then before we'll still reload
      // it but we'll allow the old values to show while we do.
      this.trove.loading = this.troveHasChanged(groupId, troveId);
      this.trove.groupId = groupId;
      this.trove.troveId = troveId;
      this.queryHistory = getQueryHistory(groupId, troveId);
      let activeQuery = getStoredActiveQuery(groupId, troveId);
      if (activeQuery) {
        this.activeQuery = activeQuery;
      }

      const trove = await pullTrove(groupId, troveId);
      if (this.troveHasChanged(groupId, troveId)) {
        // Another loadTrove started while we were doing this one.
        return
      }

      this.rawDataSets = trove.dataSets;
      this.datasetsCursor = trove.endCursor;
      this.hasAdditionalDataSets = trove.hasNextPage;
      this.trove.datasets = this.rawDataSets.map(r => r.dataSetId)
      this.trove.name = trove.name;
      this.trove.description = trove.description;
      if (this.trove.datasets && activeQuery === null) {
        const dataSetId = this.trove.datasets[0];
        activeQuery = `-- Welcome to the ${troveId} trove!\n`;
        activeQuery += "-- Here is a sample query to get started!\n";
        activeQuery += `SELECT\n  *\nFROM\n  ${dataSetId}\nLIMIT\n  10`;
      }
      this.activeQuery = activeQuery || "";
      this.trove.loading = false;

      while (this.hasAdditionalDataSets) {
        const response = await pullDefaultDataSets(groupId, troveId, this.datasetsCursor)
        if (this.trove.groupId != groupId || this.trove.troveId != troveId) {
          // Another loadTrove started while we were doing this one.
          return
        }
        this.rawDataSets.push(...response.dataSets);
        this.trove.datasets = this.rawDataSets.map(r => r.dataSetId)
        this.datasetsCursor = response.cursor;
        this.hasAdditionalDataSets = response.hasNextPage;
      }
    },
    async loadDataset(dataSetId) {
      const match = this.rawDataSets.filter(r => r.dataSetId === dataSetId);
      if (match.length === 0) {
        throw `${dataSetId} does not exist in ${this.trove.groupId}/${this.trove.troveId}`
      }
      this.datasets[dataSetId] = {
        dataSetId,
        loading: true,
        description: "",
        fields: {},
      }
      const dataSet = await pullDataSet(match[0].manifestUrl);
      this.datasets[dataSetId].loading = false;
      this.datasets[dataSetId] = {
        dataSetId,
        loading: false,
        description: dataSet.description,
        fields: dataSet.fields,
      };
    },
    pushActiveQuery(query) {
      pushStoredActiveQuery(this.trove.groupId, this.trove.troveId, query);
      this.queryHistory = getQueryHistory(this.trove.groupId, this.trove.troveId);
      this.activeQuery = query;
    },
    updateActiveQuery(value) {
      replaceStoredActiveQuery(this.trove.groupId, this.trove.troveId, value);
      this.activeQuery = value;
    },
  }
})