<template lang="pug">
aside.SideMenu.menu
  ul.menu-list
    li(v-for="item in (items || [])" :key="item.to")
      router-link.SideMenu_item(:to="item.to" :class="{'is-active': item.isActive}")
        span.SideMenu_icon(v-if="item.icon" :class="{'SideMenu_icon--inverted': item.isActive}")
          img(:src="item.icon")
        span {{ item.text }}
</template>
<script>

export default {
  props: {
    items: Array,
  }
}
</script>
<style lang="scss" scoped>
.SideMenu {

  &_item {
    display: flex;
    align-items: center;
  }

  &_icon {
    margin-right: 6px;

    &--inverted {
      filter: invert(100%);
    }
  }
}
</style>