import ClientOAuth2 from "client-oauth2";

const AUTH_DOMAIN = "https://auth.lets-quander.com";
const AUTH_URL = `${AUTH_DOMAIN}/oauth2/authorize`;
const TOKEN_URL = `${AUTH_DOMAIN}/oauth2/token`;
const LOGOUT_URL = `${AUTH_DOMAIN}/logout`;
const CLIENT_ID = "4nen89hh8q69op51igfr75fbq5";
const authClient = new ClientOAuth2({
  clientId: CLIENT_ID,
  authorizationUri: AUTH_URL,
  accessTokenUri: TOKEN_URL,
  redirectUri: `${window.location.origin}/login`,
  scopes: ['openid']
});

function sha256(plain) {
  const encoder = new TextEncoder();
  const data = encoder.encode(plain);
  return window.crypto.subtle.digest('SHA-256', data);
}

function base64urlencode(a) {
  // Convert the ArrayBuffer to string using Uint8 array.
  // btoa takes chars from 0-255 and base64 encodes.
  // Then convert the base64 encoded to base64url encoded.
  // (replace + with -, replace / with _, trim trailing =)
  // return a.toString('base64')
  return btoa(String.fromCharCode.apply(null, new Uint8Array(a)))
      .replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
}

async function pkce_challenge_from_verifier(v) {
  const hashed = await sha256(v);
  const base64encoded = base64urlencode(hashed);
  return base64encoded;
}

export async function createLoginLink() {
  let array = new Uint32Array(10);
  crypto.getRandomValues(array);
  const state = array.join('');
  crypto.getRandomValues(array);
  const codeVerifier = array.join('');
  const link = authClient.code.getUri({
    state: state,
    query: {
      code_challenge_method: 'S256',
      code_challenge: await pkce_challenge_from_verifier(codeVerifier)
    }
  });
  window.localStorage.setItem('oauthState', JSON.stringify({codeVerifier, state}));
  return link;
}

export function createLogoutLink() {
  const logoutRedirect = `${window.location.origin}/logout`;
  const params = new URLSearchParams({ logout_uri: logoutRedirect, client_id: CLIENT_ID });
  return `${LOGOUT_URL}?${params.toString()}`
}

export function logout() {
  localStorage.removeItem('auth')
}

export async function retrieveTokens(uri) {
  const oauthState = JSON.parse(window.localStorage.getItem('oauthState'));
  const tokens = await authClient.code.getToken(uri, {
    state: oauthState.state,
    body: {
      code_verifier: oauthState.codeVerifier,
    }
  });
  storeTokens({
    accessToken: tokens.accessToken,
    refreshToken: tokens.refreshToken,
    idToken: tokens.data.id_token,
    expires: tokens.expires,
  });
}

function storeTokens(tokens) {
  const existing = loadTokens()
  const { accessToken, refreshToken, idToken } = tokens;
  window.localStorage.setItem(
    'auth', JSON.stringify({
      accessToken: accessToken || existing.accessToken,
      refreshToken: refreshToken || existing.refreshToken,
      idToken: idToken || existing.idToken,
      expires: tokens.expires,
    })
  );
}

function loadTokens() {
  const tokens = JSON.parse(window.localStorage.getItem('auth'))
  if (!tokens) {
    return null;
  }
  const { accessToken, refreshToken, expires, idToken } = tokens;
  return {
    accessToken,
    refreshToken,
    idToken,
    expires: new Date(expires),
  };
}

export async function getSessionAccessToken() {
  let tokens = loadTokens()
  if (!tokens) {
    return null;
  }
  // If it will expire within the next 5 minutes then just get a new one.
  const deadline = new Date((new Date()).getTime() + 5 * 60 * 1000);
  if (tokens.expires < deadline) {
    const body = new URLSearchParams({
      'refresh_token': tokens.refreshToken,
      'grant_type': 'refresh_token',
      'client_id': CLIENT_ID,
    });
    const response = await fetch(TOKEN_URL, { method: 'POST', body: body });
    const data = await response.json();
    storeTokens({
      refreshToken: data.refresh_token,
      accessToken: data.access_token,
      idToken: data.id_token,
      expires: new Date((new Date()).getTime() + data.expires_in * 1000),
    });
    tokens = loadTokens();
  }
  return tokens.accessToken;
}

export function getUsername() {
  const tokens = loadTokens()
  try {
    const claims = JSON.parse(atob(tokens.idToken.split('.')[1]));
    return claims["cognito:username"];
  } catch (e) {
    return null;
  }
}

export function isLoggedIn() {
  return loadTokens() !== null;
}
