<template lang="pug">
CommonLayout(:crumbItems="crumbs" :sideItems="sideItems")
  .main
    h1.title {{ headerText }}
    .field
      label.label Title
      .control
        input.input(v-model="title")
    .field
      label.label Description
      .control
        textarea.textarea(v-model="description")
    .field
      label.label Sharing
      p Should this query be public or private?
      .control
        label.radio
          input(
            type="radio"
            name="accessLevel"
            value="public"
            v-model="inputTemplateAccess"
            :disabled="updatingTemplate"
          )
          | Public
        label.radio
          input(
            type="radio"
            name="accessLevel"
            value="private"
            v-model="inputTemplateAccess"
            :disabled="updatingTemplate"
          )
          | Private
    .field
      label.label Query
      .control
        monaco-editor.QueryEditor(
          language="sql"
          :value='query'
          @change="value => query = value"
          :options="{ automaticLayout: true }"
        )
    .content
      .content(v-for="(param, index) in parameters" :key="index")
        hr
        .ParamHeader
          label.label.is-medium Parameter {{ index + 1 }}
          button.delete(@click="removeParam(index)")
        .field
          label.label Name
          .control
            input.input(:value="param.name" @input="e => handleParamNameInput(index, e.target.value)")
        .field
          label.label Token
          .control
            input.input(:value="param.token" @input="e => handleParamTokenInput(index, e.target.value)")
        .field
          label.label Description
          .control
            textarea.textarea(v-model="param.description")
      router-link(:to="{query: {paramCount: paramCount + 1}}") Add parameter
    .buttons
      button.button.is-light Cancel
      button.button.is-info(
        :disabled="saving"
        @click="handleSave"
      ) {{ saving ? "Saving" : "Save" }}
</template>
<script>
import { h } from 'vue'
import MonacoEditor from 'monaco-editor-vue3';
import { troveIdToLinks, mapRouteParams } from '@/router'
import CommonLayout from '@/templates/CommonLayout.vue'
import Tabs from '@/components/Tabs.vue'
import SimpleListItem from '@/components/SimpleListItem.vue'
import { createTemplate, getTemplate, updateTemplate } from '@/services/quanderApi';
import { getUsername } from "@/services/authorization";


MonacoEditor.render = () => h('div');

function toToken(value) {
  return value.toLowerCase().replace(/[^a-z0-9_\- ]/gi, '').replace(/[_\- ]/gi, '_');
}

export default {
  name: 'TemplateEditor',
  components: {
    CommonLayout,
    MonacoEditor,
    SimpleListItem,
    Tabs,
  },
  data: () => ({
    saving: false,
    inputTemplateAccess: 'public',
    loading: true,
    title: '',
    description: '',
    query: '',
    parameters: [],
  }),
  computed: {
    isPublic: function () {
      const access = this.templateAccess || this.inputTemplateAccess;
      return access === 'public';
    },
    crumbs: function () {
      const links = troveIdToLinks(this.groupId, this.troveId, false);
      links.push({
        text: "Save Query",
        isActive: true,
        to: { name: "TroveSaveQuery", group: this.groupParts, trove: this.troveId },
      });
      return links;
    },
    groupId: function () {
      return (this.groupParts || []).join("/");
    },
    paramCount: function () {
      return parseInt(this.$route.query.paramCount) || 0;
    },
    sideItems: function () {
      return [];
    },
    inTemplateMode: function () {
      return this.$route.name.includes("Template");
    },
    updatingTemplate: function () {
      return this.templateId !== undefined;
    },
    templateGroupId: function () {
      if (this.inTemplateMode) {
        return "public";
      }
      const username = this.templateUsername || getUsername();
      return `${username}-${this.access}`;
    },
    headerText: function () {
      const action = this.updatingTemplate ? "Update" : "Create";
      const noun = this.inTemplateMode ? "Template" : "Query";
      return `${action} ${noun}`;
    },
    namespace: function () {
      return `${this.groupId}/${this.troveId}`;
    },
    templateGroup: function () {
      const access = this.isPublic ? "public" : "private";
      const username = this.templateUsername || getUsername();
      return `${username}-${access}`;

    },
    ...mapRouteParams({
      injectedQuery: "query",
      groupParts: "group",
      troveId: "trove",
      templateId: "templateId",
      templateUsername: "username",
      templateAccess: "access",
    }),
  },
  methods: {
    removeParam: function (index) {
      this.parameters.splice(index, 1);
      this.$router.replace({query: {paramCount: this.paramCount - 1}});
    },
    handleParamNameInput: function (index, value) {
      const startName = this.parameters[index].name;
      this.parameters[index].name = value;
      if (toToken(startName) === this.parameters[index].token) {
        this.parameters[index].token = toToken(value);
      }
    },
    handleParamTokenInput: function (index, value) {
      if (this.parameters[index].token === toToken(value)) {
        // In order for the value to update away from what the user just
        // put in we need to force a change.
        this.parameters[index].token = "";
      }
      this.parameters[index].token = toToken(value);
    },
    padOutParams: function (expectedCount) {
      const needed = expectedCount - this.parameters.length;
      if (needed < 1) {
        return;
      }
      const toAdd = Array(needed).fill(0).map(() => ({
        token: '',
        name: '',
        description: '',
      }));
      this.parameters = this.parameters.concat(toAdd);
    },
    handleSave: async function () {
      this.saving = true;
      let templateId = undefined;
      if (this.updatingTemplate) {
        templateId = this.templateId;
        await updateTemplate(
          this.namespace,
          this.templateGroup,
          this.templateId,
          this.title,
          this.description,
          this.query,
          this.parameters
        );
      } else {
        templateId = await createTemplate(
          this.namespace,
          this.templateGroup,
          this.title,
          this.description,
          this.query,
          this.parameters
        );
      }
      this.saving = false;
      const access = this.isPublic ? "public" : "private";
      const username = getUsername();
      this.$router.push({
        name: 'TroveSavedQuery',
        params: {
          group: this.groupParts,
          trove: this.troveId,
          username,
          access,
          templateId
        }
      })
    },
  },
  beforeMount: async function () {
    if (this.updatingTemplate) {
      const result = await getTemplate(
        this.namespace,
        this.templateGroup,
        this.templateId,
      );
      this.inputTemplateAccess = this.templateAccess;
      this.title = result.title;
      this.description = result.description;
      this.query = result.body;
      this.parameters = result.parameters;
      this.loading = false;
    } else {
      if (this.injectedQuery) {
        this.query = this.injectedQuery;
        this.loading = false;
      }
      this.padOutParams(this.paramCount);
    }
  },
  watch: {
    paramCount: function (val) {
      this.padOutParams(parseInt(val));
    }
  }
}
</script>
<style lang="scss" scoped>
  .main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
  .QueryEditor {
    height: 60vh;
    border: 1px solid #eee;
    margin-bottom: 1.3em;
  }
  .ParamHeader {
    display: flex;
    justify-content: space-between;
  }
</style>
