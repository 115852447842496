<template lang="pug">
minimal-layout(:loading="trove.loading")
  .TroveEditor
    common-secondary-navbar.GaSecondary(:crumbItems="crumbs")
      .actionsContainer
        .buttonsGroup
          .buttons
            button.button(:disabled="disableHistoryPrevious" @click="handlePrevious") Previous
            button.button(:disabled="disableHistoryNext" @click="handleNext") Next
        .buttonsGroup
          .buttons
            button.button(@click="formatQuery") Format
            router-link.button.is-primary(
              v-if="username"
              :to="{name: 'TroveSaveQuery', params: { group: groupParts, trove: troveId, query: activeQuery }}"
            )
              span Save
              span.icon
                img(src="@/assets/save.svg")
            router-link.button.is-light(
              v-if="username"
              :to="{name: 'TroveSavedQueries', params: {group: groupParts, trove: troveId, username }}"
            )
              span My Queries
              span.icon
                img(src="@/assets/library.svg")
            router-link.button.is-light(
              :to="{name: 'TroveTemplates', params: {group: groupParts, trove: troveId}}"
            )
              span Templates
              span.icon
                img(src="@/assets/compass.svg")
            button.button.is-info(:disabled="submitting" @click="() => handleSubmitQuery()")
              span {{ submitting ? "Submitting" : "Run" }}
              span.icon
                img(src="@/assets/play.svg")
    .GaLeft
      docs-panel.DocsPanel(
        v-if="!trove.loading && docsExpanded"
        :selectedDoc="$route.query.dataset || null"
        @docSelected="$router.push({ query: { ...$route.query, dataset: $event } })"
        :troveDescription="trove.description"
        :docIds="datasetIds"
        :docDescription="activeDataset.description || ''"
        :docFields="activeDataset.fields || {}"
        :loading="activeDataset.loading"
      )
      .Expander(
        @click="$router.push({ query: { ...$route.query, expanded: docsExpanded ? '0' : '1' } })"
      ) {{ docsExpanded ? "❮" : "❯" }}
    monaco-editor(
      class='GaMain'
      :value='historicalQuery || activeQuery'
      @change="handleQueryInput"
      language='sql'
      :options="{ automaticLayout: true }"
    )
</template>
<script>
import { mapActions, mapState } from 'pinia'
import { h } from 'vue'
import MonacoEditor from 'monaco-editor-vue3';
import { format } from 'sql-formatter';
import { getUsername } from "@/services/authorization";
import MinimalLayout from '@/templates/MinimalLayout.vue'
import CommonSecondaryNavbar from '@/components/CommonSecondaryNavbar.vue'
import { useTrovesStore } from '@/store'
import { troveIdToLinks } from '@/router'
import { submitQuery } from '@/services/quanderApi';
import DocsPanel from './DocsPanel'

MonacoEditor.render = () => h('div');

async function handleSubmitQuery() {
  const query = this.historicalQuery || this.activeQuery;
  this.submitting = true;
  this.pushActiveQuery(query);
  if (this.historicalQuery) {
    this.historyPosition = -1;
  }
  const queryId = await submitQuery(this.groupId, this.troveId, query);
  this.submitting = false;
  this.$router.push({name: 'QueryResults', params: {group: this.groupParts, trove: this.troveId, queryId}});
}

export default {
  name: 'TroveEditor',
  data: () => ({
    submitting: false,
    historyPosition: -1
  }),
  components: {
    DocsPanel,
    MinimalLayout,
    MonacoEditor,
    CommonSecondaryNavbar,
  },
  computed: {
    crumbs: function () {
      return troveIdToLinks(this.$route.params.group.join("/"), this.$route.params.trove, true);
    },
    troveId: function () {
      return this.$route.params.trove;
    },
    groupId: function () {
      if (!this.$route.params.group) {
        return null;
      }
      return this.$route.params.group.join("/");
    },
    groupParts: function () {
      return this.$route.params.group;
    },
    activeDataset: function () {
      const selected = this.$route.query.dataset;
      return this.datasets[selected] || { description: null, loading: true };
    },
    datasetIds: function () {
      return this.trove.datasets || [];
    },
    docsExpanded: function () {
      const expanded = this.$route.query.expanded;
      return expanded === "0" ? false : true;
    },
    username: function () {
      return getUsername();
    },
    historicalQuery: function () {
      if (this.historyPosition < 0) {
        return null;
      }
      return this.queryHistory[this.historyPosition];
    },
    disableHistoryPrevious: function () {
      return this.queryHistory.length <= this.historyPosition + 1;
    },
    disableHistoryNext: function () {
      return this.historyPosition === -1;
    },
    ...mapState(useTrovesStore, ['activeQuery', 'trove', 'datasets', 'queryHistory']),
  },
  methods: {
    handleSubmitQuery,
    handlePrevious: function () {
      const working = this.historicalQuery || this.activeQuery;
      let i = this.historyPosition + 1;
      while (i + 1 < this.queryHistory.length && this.queryHistory[i] === working) {
        i++;
      }
      this.historyPosition = i;
    },
    handleNext: function () {
      const working = this.historicalQuery || this.activeQuery;
      let i = this.historyPosition - 1;
      while (i >= 0 && this.queryHistory[i] === working) {
        i--;
      }
      this.historyPosition = i;
    },
    formatQuery: function () {
      const formatted = format(this.activeQuery, {uppercase: true});
      this.updateActiveQuery(formatted);
    },
    handleQueryInput: function (value) {
      if (this.historicalQuery) {
        this.pushActiveQuery(this.activeQuery);
        this.historyPosition = -1;
      }
      this.updateActiveQuery(value);
    },
    ...mapActions(
      useTrovesStore,
      ['loadTrove', 'loadDataset', 'pushActiveQuery', 'updateActiveQuery']
    )
  },
  watch: {
    "$route.query.dataset": function () {
      const dataset = this.$route.query.dataset;
      if (dataset && !this.datasets[dataset]) {
        this.loadDataset(dataset);
      }
    }
  },
  beforeMount: function () {
    const groupId = this.$route.params.group.join("/");
    const troveId = this.$route.params.trove;
    const selected = this.$route.query.dataset;
    this.loadTrove(groupId, troveId).then(() => {
      if (selected) {
        this.loadDataset(selected);
        return;
      }
      this.$router.replace({ query: { dataset: this.trove.datasets[0] } });
    }).catch(err => {
      if (err["type"] === "NotFoundError") {
        this.$router.push({name: "NotFound"});
        return;
      }
      throw err
    });
  },
}
</script>
<style lang="scss" scoped>
  .TroveEditor {
    flex-grow: 1;
    max-width: 100vw;
    display: grid;
    grid-template-rows: auto 1fr;
    grid-template-columns: auto 1fr;
    grid-template-areas:
      "secondary secondary"
      "left main";
  }
  .actionsContainer {
    display: flex;
  }
  .buttonsGroup {
    padding-left: 1.5em;
  }
  .GaSecondary {
    grid-area: secondary;
    padding-left: 1em;
  }
  .GaLeft {
    grid-area: left;
    display: flex;
  }
  .Expander {
    display: flex;
    align-items: center;
    font-size: 15px;
    color: gray;
    padding: 7px;
    cursor: pointer;
  }
  .DocsPanel {
    padding-left: 1em;
    width: 30em;
    height: 85vh;
    overflow-y: scroll;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */

    &:-webkit-scrollbar {
      display: none;  /* Safari and Chrome */
    }
  }
  ::-webkit-scrollbar {
    background: transparent;
  }
  .GaMain {
    grid-area: main;
    border: 1px solid #eee;
    overflow: hidden;
  }
</style>
