<template lang="pug">
CommonLayout(:crumbItems="crumbs" :sideItems="sideItems")
  .main(v-if="group")
    h1.title {{ group.name }}
    Tabs(:items="tabItems")
    SimpleListItem(
      v-if="panel === 'troves'"
      v-for="trove in group.troves"
      :title="trove.name"
      :content="trove.description"
      :to="{ name: 'TroveEditor', params: { group: group.id.split('/') , trove: trove.id } }"
    )
    SimpleListItem(
      v-if="panel === 'subgroups'"
      v-for="subgroup in group.subgroups"
      :title="subgroup.name"
      :content="subgroup.description"
      :to="{ name: 'Group', params: { group: subgroup.id.split('/') } }"
    )
</template>
<script>
import { mapActions, mapState } from 'pinia'
import { groupIdToLinks } from '@/router'
import CommonLayout from '@/templates/CommonLayout.vue'
import Tabs from '@/components/Tabs.vue'
import SimpleListItem from '@/components/SimpleListItem.vue'
import { useGroupsStore } from '@/store'

export default {
  name: 'Group',
  components: {
    CommonLayout,
    SimpleListItem,
    Tabs,
  },
  computed: {
    crumbs: function () {
      return groupIdToLinks(this.groupId, true);
    },
    panel: function () {
      return this.$route.query.panel === 'subgroups' ? 'subgroups' : 'troves';
    },
    tabItems: function () {
      const onTroves = this.panel === 'troves';
      const items = [
        {
          text: "Troves",
          to: { query: { panel: 'troves' } },
          isActive: onTroves,
        },
      ];
      if (!this.group.isPersonal) {
        items.push({
          text: "Subgroups",
          to: { query: { panel: 'subgroups' } },
          isActive: !onTroves,
        });
      }
      return items;
    },
    groupId: function () {
      if (!this.$route.params.group) {
        return null;
      }
      return this.$route.params.group.join("/");
    },
    sideItems: function () {
      const groupParts = this.groupId.split("/");
      const items = [
        {
          text: "Group",
          to: { name: 'Group', params: { group: groupParts } },
          isActive: true,
          icon: null
        },
      ]
      if (!this.group.isPersonal) {
        items.push({
          text: "Members",
          to: { name: 'GroupMembers', params: { group: groupParts } },
          isActive: false,
          icon: require("@/assets/members.svg"),
        });
      }
      return [];
    },
    ...mapState(useGroupsStore, {
      group: 'group'
    })
  },
  methods: {
    ...mapActions(useGroupsStore, ['loadGroup'])
  },
  beforeMount: function () {
    const groupId = this.$route.params.group.join("/");
    this.loadGroup(groupId);
  },
  watch: {
    '$route.params.group': function (val) {
      if (!val) {
        this.loadGroup(val.join("/"));
      }
    }
  }
}
</script>
<style scoped>
  .main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
</style>
