<template lang="pug">
.DocsPanel.content
  h5.title.is-5 Documentation
  p.UserMarkdown(v-html="renderedTroveDescription")
  h6.title.is-6 Dataset
  .DocInput
    .select
      select(
        :value="selectedDoc"
        v-on:change="$emit('docSelected', $event.target.value); $event.preventDefault()"
      )
        option(v-for="value in docIds" :value="value") {{ value }}
  p.UserMarkdown(v-html="renderedDocDescription")
  div(v-if="loading")
    .LineFiller(style="width:75%;")
    .LineFiller(style="width:60%;")
    .LineFiller(style="width:80%;")
  h6.title.is-6(v-if="Object.keys(sortedFields).length > 0") Fields
  .FieldItem(v-for="f in sortedFields")
    .FieldHeader
      code.FieldName {{ f.name }}
      b.FieldType {{ f.type }}
    .FieldBody.UserMarkdown(v-html="f.description")
</template>
<script>
import { marked } from 'marked'
import DOMPurify from 'dompurify';

export default {
  name: 'DocsPanel',
  props: {
    selectedDoc: {
      type: String,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    troveDescription: {
      type: String,
      required: true,
    },
    docDescription: {
      type: String,
      required: true,
    },
    docIds: {
      type: Array,
      required: true,
    },
    docFields: {
      type: Object,
      required: true,
    }
  },
  computed: {
    sortedFields: function () {
      return Object.entries(this.docFields).map(([k, v]) => (
        {
          name: k,
          description: DOMPurify.sanitize(
            marked.parse(v.description),
            {FORBID_TAGS: ['style']}
            ),
          type: v.type
        }
      )).sort((a, b) => a.name >= b.name);
    },
    renderedDocDescription: function () {
      return DOMPurify.sanitize(marked.parse(this.docDescription), {FORBID_TAGS: ['style']})
    },
    renderedTroveDescription: function () {
      return DOMPurify.sanitize(marked.parse(this.troveDescription), {FORBID_TAGS: ['style']})
    },
  },
}
</script>
<style lang="scss" scoped>
.DocsPanel {
}
.DocInput {
  padding-bottom: 20px;
}
.FieldHeader {
  display: flex;
  justify-content: space-between;
  padding-bottom: 6px;
}
.FieldName {
  font-weight: 700;
}
.FieldBody {
  padding-left: 0.75em;
}
.FieldItem {
  padding-bottom: 10px;
}
.UserMarkdown {
  &::v-deep h1 {
    font-size: 16px;
    font-weight: 700;
  }
  &::v-deep h2 {
    font-size: 16px;
    font-weight: 660;
  }
  &::v-deep h3 {
    font-size: 16px;
    font-weight: 620;
  }
  &::v-deep h4, &::v-deep h5, &::v-deep h5 {
    font-size: 16px;
    font-weight: 580;
  }
}
.LineFiller {
  height: 1.25em;
  margin: 5px;
  background: whitesmoke;
}

</style>