import { getSessionAccessToken } from '@/services/authorization';
const URL = 'https://jay.api.lets-quander.com/graphql';

const TOKENS = {
  jay: 'da2-if2uvd3fqjgmfj2hc57fkvt5ra',
  kea: 'da2-nmzqc7rwlfazxekh3y7iiz76e4',
  macaw: 'da2-bpfv5y22tzbevby5tlql7avvjq',
  raven: 'da2-akmaldu6r5d33ecu5dbt45kyii',
}


export async function fetchGraphQL(query, variables) {
  const accessToken = await getSessionAccessToken();
  const headers = accessToken ? {'Authorization': accessToken} : {'x-api-key': TOKENS.jay};
  const response = await fetch(
    URL,
    {
      method: 'POST',
      headers,
      body: JSON.stringify({query, variables}),
    },
  );
  return await response.json();
}
