import { defineStore } from 'pinia'
import { getTemplate, getTemplates } from '@/services/quanderApi'


export const useTemplatesStore = defineStore('templates', {
  state: () => {
    return {
      groupId: '',
      troveId: '',
      templateGroupId: '',
      hasNextPage: true,
      cursor: null,
      templates: [],
      loading: true,
      activeTemplateId: '',
      activeTemplateLoading: true,
      activeTemplateTitle: '',
      activeTemplateDescription: '',
      activeTemplateBody: '',
      activeTemplateUpdatedAt: '',
      activeTemplateParameters: '',
    };
  },
  actions: {
    /**
     * Reset the store to the default state and initialize it for the specified.
     * templateGroup.
     *
     * @param {String} groupId The group in which the trove lives
     * @param {String} troveId The trove in which the template group lives
     * @param {String} templateGroupId The group of templates.
     */
    reset(groupId, troveId, templateGroupId) {
      this.loading = true;
      this.groupId = groupId;
      this.troveId = troveId;
      this.templateGroupId = templateGroupId;
      this.hasNextPage = true;
      this.cursor = null;
      this.templates = [];
      this.resetActiveTemplate('');
    },
    /**
     * Reset the active template to an empty state.
     *
     * @param {String} templateId The id of the template.
     */
    resetActiveTemplate(templateId) {
      this.activeTemplateId = templateId;
      this.activeTemplateLoading = true;
      this.activeTemplateTitle = '';
      this.activeTemplateDescription = '';
      this.activeTemplateBody = '';
      this.activeTemplateUpdatedAt = '';
      this.activeTemplateParameters = '';
    },
    /**
     * Load all of the public saved queries for a specified templateGroup.
     *
     * @param {String} groupId The group in which the trove lives.
     * @param {String} troveId The trove the templates lives in.
     * @param {String} templateGroupId The group of templates.
     */
    async loadTemplateGroup(groupId, troveId, templateGroupId) {
      if (this.templateGroupHasChanged(groupId, troveId, templateGroupId)) {
        this.reset(groupId, troveId, templateGroupId);
      }
      if (!this.hasNextPage) {
        return;
      }

      this.loading = true;
      const response = await getTemplates(
        `${groupId}/${troveId}`,
        templateGroupId,
        this.cursor,
      );
      if (this.templateGroupHasChanged(groupId, troveId, templateGroupId)) {
        return;
      }
      this.loading = false;

      this.templates = response.templates;
      this.cursor = response.cursor;
      this.hasNextPage = response.hasNextPage;
    },
    async loadTemplate(groupId, troveId, templateGroupId, templateId) {
      if (this.templateGroupHasChanged(groupId, troveId, templateGroupId)) {
        this.reset(groupId, troveId, templateGroupId);
      }
      this.resetActiveTemplate(templateId);
      const response = await getTemplate(
        `${groupId}/${troveId}`,
        templateGroupId,
        templateId
      );
      if (this.templateHasChanged(groupId, troveId, templateGroupId, templateId)) {
        return;
      }
      this.activeTemplateTitle = response.title;
      this.activeTemplateDescription = response.description;
      this.activeTemplateBody = response.body;
      this.activeTemplateUpdatedAt = response.updatedAt;
      this.activeTemplateParameters = response.parameters;
      this.activeTemplateLoading = false;
    }
  },
  getters: {
    templateGroupHasChanged: (state) => {
      function checker(groupId, troveId, templateGroupId) {
        return (
          state.groupId !== groupId ||
          state.troveId !== troveId ||
          state.templateGroupId !== templateGroupId
        );
      }
      return checker;
    },
    templateHasChanged: (state) => {
      function checker(groupId, troveId, templateGroupId, templateId) {
        return (
          state.groupId !== groupId ||
          state.troveId !== troveId ||
          state.templateGroupId !== templateGroupId ||
          state.activeTemplateId !== templateId
        );
      }
      return checker;
    }
  }
})
