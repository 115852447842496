<template lang="pug">
CommonLayout(:crumbItems="crumbs" :sideItems="sideItems")
  .main(v-if="loading")
    .TitleFiller(style="width:30%;")
    .content
      .LineFiller(style="width:80%;")
    h5.title.is-5 Query
    pre.content
      code
        .CodeFiller(style="width:15%;")
        .CodeFiller(style="width:30%;")
        .CodeFiller(style="width:20%;")
        .CodeFiller(style="width:25%;")
        .CodeFiller(style="width:15%;")
  .main(v-else)
    .HeaderContainer
      h1.title {{ title }}
      router-link.button.is-light(
        v-if="isUsersPage"
        :to="{name: 'TroveEditQuery', params: { group: groupParts, trove: troveId, access, templateId }}"
      ) Edit
    p.content {{ description }}
    h5.title.is-5 Query
    pre.content
      code
        span(
          v-for="piece in formattedBodyPieces"
          :class="piece.class"
          :key="piece.index"
        ) {{ piece.text }}
    h5.title.is-5(v-if="parameters.length > 0") Parameters
    .field(v-for="param in parameters" :key="param.token")
      label.label {{ param.name }}
      p.help {{ param.description }}
      .control
        input.input(
          type="text"
          :placeholder="param.token"
          :value="queryArgs[param.token]"
          @input="(event) => handleQueryArgInput(param.token, event.target.value)"
          @focus="selected = param.token"
          @blur="selected = null"
        )
    .buttons
      button.button.is-light(@click="handleRender") Render
      button.button.is-info(:disabled="submitting" @click="handleRun")
        span {{ submitting ? "Submitting" : "Run" }}
        span.icon
          img(src="@/assets/play.svg")
</template>
<script>
import { mapActions, mapState } from 'pinia'
import { templateLinks, savedQueryLinks, mapRouteParams } from '@/router'
import { getUsername } from "@/services/authorization";
import CommonLayout from '@/templates/CommonLayout.vue'
import Tabs from '@/components/Tabs.vue'
import SimpleListItem from '@/components/SimpleListItem.vue'
import { submitQuery } from '@/services/quanderApi';
import { useTemplatesStore, pushStoredActiveQuery } from '@/store'


export default {
  name: 'Template',
  data: () => ({
    selected: null,
    submitting: false,
  }),
  components: {
    CommonLayout,
    SimpleListItem,
    Tabs,
  },
  computed: {
    crumbs: function () {
      if (this.inTemplateMode) {
        return templateLinks(this.groupId, this.troveId, this.templateId, true);
      }
      return savedQueryLinks(
        this.groupId,
        this.troveId,
        this.pageUsername,
        this.access,
        this.templateId,
        true
      );
    },
    isUsersPage: function () {
      return getUsername() === this.pageUsername;
    },
    groupId: function () {
      if (!this.$route.params.group) {
        return null;
      }
      return this.$route.params.group.join("/");
    },
    sideItems: function () {
      return [];
    },
    inTemplateMode: function () {
      return this.$route.name !== 'TroveSavedQuery';
    },
    templateGroupId: function () {
      if (this.inTemplateMode) {
        return "public";
      }
      return `${this.pageUsername}-${this.access}`;
    },
    queryArgs: function () {
      return this.parameters.reduce((aggr, value) => {
        aggr[value.token] = this.$route.query[value.token] || '';
        return aggr;
      }, {});
    },
    formattedBodyPieces: function () {
      if (!this.parameters || !this.body) {
        return this.body ? [this.body] : [];
      }
      const allowedTokens = this.parameters.map(param => param.token).join('|');
      const fullExpression = new RegExp(`(\\$\\{\\W*(?:${allowedTokens})\\W*\\})`);
      const justToken = new RegExp(`\\$\\{\\W*(${allowedTokens})\\W*\\}`);
      const parts = this.body.split(fullExpression);
      return parts.map((part, index) => {
        if (fullExpression.test(part)) {
          const token = part.match(justToken)[1];
          const result = (this.queryArgs[token] || part);
          const className = (
            token === this.selected ? 'highlighted--selected' : 'highlighted'
          );
          return {
            index,
            class: className,
            text: result
          };
        }
        return {
          index,
          class: '',
          text: part
        };
      });
    },
    renderedTemplate: function () {
      const allowedTokens = this.parameters.map(param => param.token).join('|');
      const fullExpression = new RegExp(`(\\$\\{\\W*(?:${allowedTokens})\\W*\\})`);
      const justToken = new RegExp(`\\$\\{\\W*(${allowedTokens})\\W*\\}`);
      const parts = this.body.split(fullExpression);
      return parts.map((part) => {
        if (fullExpression.test(part)) {
          const token = part.match(justToken)[1];
          const result = this.queryArgs[token];
          return result;
        }
        return part;
      }).join('');
    },
    ...mapState(useTemplatesStore, {
      loading: "activeTemplateLoading",
      title: "activeTemplateTitle",
      description: "activeTemplateDescription",
      body: "activeTemplateBody",
      parameters: "activeTemplateParameters",
    }),
    ...mapRouteParams({
      pageUsername: "username",
      access: "access",
      groupParts: "group",
      troveId: "trove",
      templateId: "templateId",
    })
  },
  methods: {
    handleQueryArgInput: function (token, value) {
      this.$router.replace({ query: { ...this.$route.query, [token]: value } });
    },
    handleLog: function (value) {
      console.log(value);
    },
    handleRender: function () {
      pushStoredActiveQuery(this.groupId, this.troveId, this.renderedTemplate);
      this.$router.push({
        name: "TroveEditor",
        params: { group: this.groupParts, trove: this.troveId }
      });
    },
    handleRun: async function () {
      this.submitting = true;
      pushStoredActiveQuery(this.groupId, this.troveId, this.renderedTemplate);
      const queryId = await submitQuery(this.groupId, this.troveId, this.renderedTemplate);
      this.submitting = false;
      this.$router.push({
        name: 'QueryResults',
        params: {group: this.groupParts, trove: this.troveId, queryId}
      });
    },
    ...mapActions(useTemplatesStore, ["loadTemplate"]),
  },
  beforeMount: function () {
    this.loadTemplate(this.groupId, this.troveId, this.templateGroupId, this.templateId);
  },
}
</script>
<style lang="scss" scoped>
  .main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
  .HeaderContainer {
    display: flex;
    justify-content: space-between;
  }
  .highlighted {
    background-color: #FFFF8F;
  }
  .highlighted--selected {
    background-color: #FFEA00;
    font-weight: bolder;
  }
  .TitleFiller {
    height: 2.3em;
    margin: 5px;
    background: whitesmoke;
  }
  .LineFiller {
    height: 1.25em;
    margin: 5px;
    background: whitesmoke;
  }
  .CodeFiller {
    height: 1.25em;
    margin-top: 5px;
    background: #E8E8E8;
  }
</style>
